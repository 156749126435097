<template>
  <div :class="['rounded pa-4', { 'secondary-lighten': isBackground }]">
    <auto-payment-dialog
      :title="$t('main.add_funds.unsubscription_description')"
      :is-shown="isShownUnsubscription"
      is-unsubscription-btn
      @close-dialog="isShownUnsubscription = false"
      @auto-payment-unsubscription="unsubscriptionAutoPayments()"
      @auto-payment-update="updateAutoPayments()"
    />

    <auto-payment-dialog
      :title="$t('main.add_funds.update_description')"
      :is-shown="isShownUpdate"
      @close-dialog="isShownUpdate = false"
      @auto-payment-update="updateAutoPayments()"
    />

    <div v-if="isTitleShow" class="d-flex align-center mb-2">
      <span class="text-body-2 black--text font-weight-medium">
        {{ $t('main.add_funds.card_autorecharge') }}
      </span>
    </div>

    <v-progress-linear v-if="isLoading" indeterminate color="primary" />

    <div v-else class="d-flex flex-column">
      <div class="d-flex flex-column flex-sm-row align-start align-sm-end">
        <c-text-field
          :value="chargeAmountLazyValue"
          :background-color="isBackground ? 'white' : 'secondary-lighten'"
          :class="['flex-grow-1', { 'global-full-width': isMobile }]"
          label-bold
          flat
          :min="100"
          :height="46"
          type="number"
          hide-details
          :error="!!errors.charge_amount"
          @input="chargeAmountLazyValue = +$event"
        >
          <template #append>
            <button :disabled="!amountHasChanged" type="button" @click="setChargeAmountTotals()">
              <v-icon size="18" :class="[!amountHasChanged ? 'secondary--text' : 'primary--text']">
                $arrow-right-outlined
              </v-icon>
            </button>
          </template>
        </c-text-field>

        <div
          :class="[
            'border-secondary rounded ml-0 ml-sm-2 mt-2 mt-sm-0 pa-2',
            { 'global-full-width': isMobile }
          ]"
        >
          <c-switch
            :input-value="autoPayment"
            readonly
            :background-white="isBackground"
            @click="isShownUnsubscription = true"
          >
            <template #label>
              <span class="text-subtitle-2 mr-3">
                {{ $t('main.add_funds.autopayment') }}
              </span>
              <c-info-btn is-tooltip>
                <template #tooltipText>
                  {{ $t('main.add_funds.auto_payment_description') }}
                </template>
              </c-info-btn>
            </template>
          </c-switch>
        </div>
      </div>

      <errors v-if="errors.charge_amount" :error-messages="errors.charge_amount" />

      <v-alert text color="info" class="text-left mt-2">
        {{ $t('main.add_funds.shift4_description') }}
      </v-alert>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CSwitch from '@clickadilla/components/ui/CSwitch.vue'
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import AutoPaymentDialog from '@/views/AddFunds/PaymentMethods/AutoPaymentDialog.vue'
  import invoicedRepository from '@/services/repositories/invoiced-repository.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'ChargeSecurionPayField',
    components: {
      Errors,
      CTextField,
      CSwitch,
      AutoPaymentDialog,
      CInfoBtn
    },
    props: {
      isTitleShow: {
        type: Boolean,
        default: true
      },
      isBackground: {
        type: Boolean,
        default: true
      },
      autoPayment: {
        type: Boolean,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      recurringPaymentsCustomer: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        isShownUnsubscription: false,
        isShownUpdate: false,
        chargeAmountLazyValue: 0,
        errors: {}
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      amountHasChanged() {
        return (
          Math.trunc(parseFloat(this.chargeAmountLazyValue) * 10000)
          !== Math.trunc(parseFloat(this.recurringPaymentsCustomer.changeAmount) * 10000)
        )
      }
    },
    watch: {
      recurringPaymentsCustomer: {
        immediate: true,
        handler(value) {
          this.chargeAmountLazyValue = value.changeAmount
        }
      }
    },
    methods: {
      ...mapActions('personal', ['resetRecurringPaymentsCustomers', 'fetchUserProfile']),
      setChargeAmountTotals() {
        this.errors.charge_amount = null
        this.isShownUpdate = true
      },
      async unsubscriptionAutoPayments() {
        try {
          await invoicedRepository.unsubscription()
          gtmPush({
            event: GTM_EVENTS.AUTOPAYMENT_OFF,
            event_category: GTM_EVENTS_CATEGORY.PAYMENT
          })
          this.resetRecurringPaymentsCustomers()
          this.isShownUnsubscription = false
        } catch (error) {
          handleErrors(error)
        }
      },
      async updateAutoPayments() {
        const params = {
          payment_method_id: this.recurringPaymentsCustomer.paymentMethodId,
          charge_amount: this.chargeAmountLazyValue,
          customer_data: {
            customer_id: this.recurringPaymentsCustomer.customerData.customerId
          }
        }
        try {
          await invoicedRepository.subscription(params)
          gtmPush({
            event: GTM_EVENTS.AUTOPAYMENT_EDIT,
            event_category: GTM_EVENTS_CATEGORY.PAYMENT
          })
          this.fetchUserProfile()
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.isShownUpdate = false
      }
    }
  }
</script>
